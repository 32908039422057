<template>
  <v-dialog v-model="bDialog" max-width="500">
    <template #activator="{ on }">
      <v-list color="grey lighten-3" rounded>
        <v-subheader>
          {{ $t("assign.companies") }}
          <v-spacer />
          <v-btn icon color="green" v-on="on">
            <icon-add size="1.2em" />
          </v-btn>
        </v-subheader>

        <template v-for="obCompany in arSelectedCompanyList">
          <v-list-item v-on="on" :key="`user-assigned-company-${obCompany.id}`">
            <v-list-item-content>
              <company-preview hide-title :company="obCompany" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>

    <v-card :loading="loading" v-if="bDialog">
      <v-list dark class="active" v-if="arSelectedCompanyList.length">
        <template v-for="(item, index) in arSelectedCompanyList">
          <v-list-item
            @click="del(item)"
            :key="`companies-added-list-item-${index}`"
          >
            <v-list-item-avatar>
              <v-img
                :src="item.preview_image"
                :alt="item.firm.name"
                v-if="item.preview_image"
              />
              <icon-camera outlined size="1.8rem" v-else />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.firm.name" />
              <v-list-item-subtitle v-text="item.firm.email" />
            </v-list-item-content>
            <v-list-item-action>
              <icon-remove size="1.5em" class="red--text" />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>

      <v-card-text>
        <v-text-field
          :placeholder="$t('search.company')"
          :label="$t('search.company')"
          v-model="sSearch"
          ref="searchField"
        ></v-text-field>
      </v-card-text>

      <v-card-text v-if="arCompanyCollectionList.length">
        <v-list rounded color="transparent">
          <template v-for="(item, index) in arCompanyCollectionList">
            <v-list-item
              @click="add(item)"
              :key="`companies-finded-list-item-${index}`"
              :class="{ primary: arCompanyList.includes(item.id) }"
            >
              <v-list-item-avatar color="grey lighten-3">
                <v-img
                  :src="item.preview_image"
                  :alt="item.firm.name"
                  v-if="item.preview_image"
                />
                <icon-camera outlined size="1.8rem" v-else />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.firm.name" />
                <v-list-item-subtitle v-text="item.firm.email" />
              </v-list-item-content>
              <v-list-item-action>
                <icon-add size="1.5em" class="green--text" />
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn small depressed @click="bDialog = false" color="primary">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import type { CompanyData } from "@planetadeleste/vue-mc-gw";
import { CompanyCollection } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, Watch, VModel, Prop } from "vue-property-decorator";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import type { DebounceFunction } from "@/plugins/helpers";
import { debounce, difference, filter, uniq } from "lodash";

@Component({
  components: { CompanyPreview },
})
export default class CompaniesPicker extends Vue {
  bDialog = false;
  sSearch = "";
  fnDebounceSearch!: DebounceFunction;
  obCompanyCollection = new CompanyCollection();

  @VModel({ type: Array, default: () => [] }) arCompanyList!: number[];
  @Prop(Boolean) readonly singleOption!: string;

  arCompanyListId: number[] = [];
  loading = false;

  @Watch("sSearch")
  onSearch() {
    this.fnDebounceSearch();
  }

  @Watch("bDialog")
  onOpenClose(bValue: boolean) {
    if (bValue) {
      this.load();
    } else {
      this.sSearch = "";
    }
  }

  get arSelectedCompanyList(): CompanyData[] {
    const arItems = this.obCompanyCollection.getModelList() as CompanyData[];

    return this.arCompanyListId.length && arItems.length
      ? filter(arItems, (obItem) => this.arCompanyListId.includes(obItem.id))
      : [];
  }

  get arCompanyCollectionList(): CompanyData[] {
    const arItems = this.obCompanyCollection.getModelList() as CompanyData[];

    return this.arCompanyListId.length
      ? filter(arItems, (obItem) => !this.arCompanyListId.includes(obItem.id))
      : arItems;
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    await this.search(true);

    if (!this.arCompanyList.length) {
      return;
    }

    this.loading = true;
    this.arCompanyListId = [...this.arCompanyList];
    const arCollectionIdList = this.obCompanyCollection.map("id") as number[];
    const arMissingIdList = difference(
      this.arCompanyListId,
      arCollectionIdList
    );
    const obCollection = new CompanyCollection();
    obCollection.page(1);
    await obCollection.filterBy({ set: arMissingIdList }).fetch();
    this.loading = false;

    if (!obCollection.length) {
      return;
    }

    obCollection.each((obModel) => {
      if (this.obCompanyCollection.find({ id: obModel.id })) {
        return;
      }

      this.obCompanyCollection.add(obModel);
    });
  }

  async search(force: boolean = false) {
    if (!this.bDialog && !force) {
      return;
    }

    this.loading = true;
    const obCollection = new CompanyCollection();
    obCollection.page(1);

    if (this.sSearch.length > 1) {
      obCollection.filterBy({ search: this.sSearch });
    }

    await obCollection.fetch();
    this.obCompanyCollection.clear();
    this.obCompanyCollection.add(obCollection.models);
    this.loading = false;
  }

  add(obCompany: CompanyData) {
    const iCompanyId = obCompany.id;

    if (
      this.arCompanyListId.includes(iCompanyId) ||
      !this.obCompanyCollection.find({ id: iCompanyId })
    ) {
      return;
    }
    if (this.singleOption) {
      this.arCompanyListId = [];
    }
    this.arCompanyListId.push(iCompanyId);
    this.apply();
  }

  del(obCompany: CompanyData) {
    if (!this.arCompanyListId.length) {
      return;
    }

    const idx = this.arCompanyListId.indexOf(obCompany.id);

    if (idx < 0) {
      return;
    }

    this.arCompanyListId.splice(idx, 1);
    this.apply();
  }

  apply(close: boolean = false) {
    this.$emit(
      "input",
      this.arCompanyListId.length ? uniq(this.arCompanyListId) : []
    );

    if (close) {
      this.bDialog = false;
    }
  }
}
</script>
